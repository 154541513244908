<div class="row page_wrapper ml-0">
  <div class="col-md-6 text-center pr-0 pl-0 d-none d-md-inline-block">
    <div class="w-100 h-100">
      <img
        src="../../../assets/images/signup-img.png"
        class="d-md-block d-none wh-inherit"
      />
    </div>
    <div class="d-flex justify-content-center">
      <div class="position-absolute d-md-block d-none bg-blur">
        <img src="../../../assets/images/logo1.png" class="mt-5" />
        <div class="border-line border-top position-absolute">
          <img src="../../../assets/images/Plug.svg" class="mt-4" />
        </div>
      </div>
    </div>
    <div class="position-relative bottom-55">
      <ngb-carousel>
        <ng-template ngbSlide>
          <div class="carousel-caption">
            <p
              *ngIf="langSelected == 'en'"
              class="text-justify font-size-14 font-weight-bold"
            >
              Describy, the customer centric marketing revolution — Discover an
              innovative solution to address your consumers and prospects. A few
              clicks are enough to create an efficient promotion campaign in
              line with your marketing campaign : online or offline, localized
              or national, to reach a targeted or a wide audience.
            </p>
            <p
              *ngIf="langSelected == 'fr'"
              class="text-justify font-size-14 font-weight-bold"
            >
              Décrivez, la révolution du marketing centré sur le client —
              Découvrez une solution innovante pour vous adresser à vos
              consommateurs et prospects. Quelques clics suffisent pour créer
              une campagne de promotion efficace en phase avec votre campagne
              marketing : en ligne ou hors ligne, localisée ou nationale, pour
              toucher un public ciblé ou large.
            </p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="carousel-caption">
            <p class="text-justify">Describy, 2.</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="carousel-caption">
            <p class="text-justify">Describy, 3.</p>
          </div>
        </ng-template>
      </ngb-carousel>
      <ul class="lang-switcher position-absolute m-0 p-0 text-change">
        <li
          class="d-inline-block p-2 font-size-14"
          [class.active]="langSelected == 'fr'"
        >
          <a
            class="text-white font-size-14 font-weight-400 d-block cursor-pointer bg-wh"
            (click)="languageSelected('fr')"
            >FR</a
          >
        </li>
        <li class="vertical-row position-absolute d-inline-block"></li>
        <li
          class="d-inline-block font-size-14 rounded-circle p-2"
          [class.active]="langSelected == 'en'"
        >
          <a
            class="text-white font-size-14 font-weight-400 d-block cursor-pointer bg-wh"
            (click)="languageSelected('en')"
            >EN</a
          >
        </li>
      </ul>
    </div>
  </div>
  <div class="col-md-6 pl-0 pr-0 my-auto">
    <div *ngIf="action == 'step1'">
      <div
        class="text-center mx-auto mt-md-4 mt-0 col-12 col-sm-10 col-md-12 col-lg-10"
      >
        <img src="https://describy.com/images/logo.svg" />
        <h4
          *ngIf="langSelected == 'en'"
          class="font-weight-bold mt-5 pt-md-5 pt-0"
        >
          ENTER YOUR EMAIL
        </h4>
        <h4
          *ngIf="langSelected == 'fr'"
          class="font-weight-bold mt-5 pt-md-5 pt-0"
        >
          ENTRER VOTRE EMAIL
        </h4>

        <form
          class="pl-md-5 pr-md-5 pl-0 pr-0 mt-4"
          [formGroup]="forgotPasswordForm"
        >
          <div class="form-group">
            <input
              type="text"
              class="form-control p-4 border-color placeholder-color"
              placeholder="Email"
              id="email"
              formControlName="email"
            />
            <div
              class="text-danger text-left mt-2"
              *ngIf="
                formControl.email.touched && formControl.email.errors?.required
              "
            >
              <small *ngIf="langSelected == 'en'"> Email is required. </small>
              <small *ngIf="langSelected == 'fr'"> L'e-mail est requis </small>
            </div>
            <div
              class="text-danger text-left mt-2"
              *ngIf="
                formControl.email.touched && formControl.email.errors?.email
              "
            >
              <small *ngIf="langSelected == 'en'"> Invalid Email. </small>
              <small *ngIf="langSelected == 'fr'"> Email invalide. </small>
            </div>
          </div>
          <p
            *ngIf="langSelected == 'en'"
            class="text-left font-weight-bold font-size-12 text-light-brown"
          >
            Email to login into your account
          </p>
          <p
            *ngIf="langSelected == 'fr'"
            class="text-left font-weight-bold font-size-12 text-light-brown"
          >
            E-mail pour vous connecter à votre compte
          </p>

          <div>
            <button
              *ngIf="langSelected == 'en'"
              type="button"
              class="btn btn-bg text-white p-3 font-size-14 font-weight-bold w-100"
              (click)="forgotMyPassword()"
              [disabled]="!forgotPasswordForm.valid"
            >
              SEND
            </button>
            <button
              *ngIf="langSelected == 'fr'"
              type="button"
              class="btn btn-bg text-white p-3 font-size-14 font-weight-bold w-100"
              (click)="forgotMyPassword()"
              [disabled]="!forgotPasswordForm.valid"
            >
              ENVOYER
            </button>
          </div>
          <div class="d-flex mt-4">
            <div class="text-left pl-0">
              <p
                *ngIf="langSelected == 'en'"
                class="text-orange font-size-12 font-weight-bold cursor-pointer"
                routerLink="/login"
                routerLinkActive="active"
              >
                Already have an account ? Sign in !
              </p>

              <p
                *ngIf="langSelected == 'fr'"
                class="text-orange font-size-12 font-weight-bold cursor-pointer"
                routerLink="/login"
                routerLinkActive="active"
              >
                Vous avez déjà un compte ? S'identifier !
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="action == 'step2'">
      <div
        class="text-center mx-auto mt-md-4 mt-0 col-12 col-sm-10 col-md-12 col-lg-10"
      >
        <img src="https://describy.com/images/logo.svg" />
        <h4 *ngIf="langSelected == 'en'" class="font-weight-bold mt-5">
          WELCOME TO THE E-&#8288;COMMERCE
        </h4>
        <h4 *ngIf="langSelected == 'en'" class="font-weight-bold">
          MANAGEMENT PLATEFORM
        </h4>

        <h4 *ngIf="langSelected == 'fr'" class="font-weight-bold mt-5">
          LA PLATEFORME DE GESTION POUR LES E-&#8288;COMMERCANTS
        </h4>

        <form
          class="pl-md-5 pr-md-5 pl-0 pr-0 mt-4"
          [formGroup]="resetForgottenPasswordForm"
        >
          <div class="form-group">
            <input
              type="text"
              class="form-control p-4 border-color placeholder-color"
              formControlName="code"
              placeholder="Enter code"
            />
            <div
              class="text-danger text-left mt-2"
              *ngIf="
                formControl.code.touched && formControl.code.errors?.required
              "
            >
              <small *ngIf="langSelected == 'en'"> Code is required. </small>
              <small *ngIf="langSelected == 'fr'"> Le code est requis. </small>
            </div>
          </div>
          <div class="form-group">
            <input
              type="Password"
              class="form-control p-4 border-color placeholder-color"
              id="password"
              formControlName="password"
              placeholder="Enter New Password"
            />
            <div
              class="text-danger text-left mt-2"
              *ngIf="
                formControl.password.touched &&
                formControl.password.errors?.required
              "
            >
              <small *ngIf="langSelected == 'en'">
                Password is required.
              </small>
              <small *ngIf="langSelected == 'fr'"> Mot de passe requis. </small>
            </div>
            <div
              class="text-danger text-left mt-2"
              *ngIf="
                formControl.password.touched &&
                formControl.password.errors?.pattern
              "
            >
              <small *ngIf="langSelected == 'en'">
                Password should be atleast 8 characters, should contain one
                uppercase letter, one lowercase letter, one special character
                and one number.
              </small>

              <small *ngIf="langSelected == 'fr'">
                Le mot de passe doit comporter au moins 8 caractères, doit
                contenir une lettre majuscule, une lettre minuscule, un
                caractère spécial et un chiffre.
              </small>
            </div>
          </div>
          <div class="form-group">
            <input
              type="Password"
              class="form-control p-4 border-color placeholder-color"
              id="cnfrmPassword"
              formControlName="cnfrmPassword"
              placeholder="Confirm Password"
            />
            <div
              class="ttext-danger text-left mt-2"
              *ngIf="
                formControl.cnfrmPassword.touched &&
                formControl.cnfrmPassword.errors?.required
              "
            >
              <small *ngIf="langSelected == 'en'">
                Confirm Password is required.
              </small>
              <small *ngIf="langSelected == 'fr'">
                Confirmer le mot de passe est requis.
              </small>
            </div>
            <div
              class="text-danger text-left mt-2"
              *ngIf="
                formControl.cnfrmPassword.touched &&
                formControl.cnfrmPassword.errors?.confirmedValidator
              "
            >
              <small *ngIf="langSelected == 'en'">
                Passwords do not match.
              </small>

              <small *ngIf="langSelected == 'fr'">
                Les mots de passe ne correspondent pas.
              </small>
            </div>
          </div>
          <div>
            <button
              *ngIf="langSelected == 'en'"
              type="button"
              class="btn btn-bg text-white p-3 font-size-14 font-weight-bold w-100"
              (click)="resetForgottenPassword()"
              [disabled]="!resetForgottenPasswordForm.valid"
            >
              SUBMIT
            </button>

            <button
              *ngIf="langSelected == 'fr'"
              type="button"
              class="btn btn-bg text-white p-3 font-size-14 font-weight-bold w-100"
              (click)="resetForgottenPassword()"
              [disabled]="!resetForgottenPasswordForm.valid"
            >
              NOUS FAIRE PARVENIR
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- <div  *ngIf="action=='step2'">
      <div class="text-center mx-auto mt-md-4 mt-0 col-12 col-sm-10 col-md-12 col-lg-10">
        <img src="../../../assets/images/logo copy.png">
        <h4 class="font-weight-bold mt-5 pt-md-5 pt-0">RECOVER PASSWORD</h4>
        <P class="font-weight-bold font-size-12 mt-4 text-light-brown">An email has been sent. Please click
          the link
          when you get it.</P>
        <a href="#" class="font-weight-bold font-size-12 text-orange text-decoration">Check inbox</a>
      </div>
   </div> -->
  </div>
</div>
