import { Component, QueryList, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ERROR_MESSAGES, PAGE_COUNT } from "src/app/common/constants";
import {
  SortEvent,
  TableSortableHeader,
} from "src/app/directives/tablesortableheader.directives";
import { Product } from "src/app/models/product";
import { ConsumerService } from "src/app/services/consumer.service";
import { CreateLinkComponent } from "./components/create-link/create-link.component";
import { DeleteLinkConfirmationComponent } from "./components/delete-link-confirmation/delete-link-confirmation.component";
const compare = (v1: any, v2: any) => {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
};
@Component({
  selector: "app-links",
  templateUrl: "./links.component.html",
  styleUrls: ["./links.component.scss"],
})
export class LinksComponent {
  public links: Product[];
  public product: Product;
  public page: number = 1;
  public pageCount: number = PAGE_COUNT;
  public totalPage: number = 0;
  public editLink: boolean = false;
  public searchText: string = "";
  @ViewChildren(TableSortableHeader) headers: QueryList<TableSortableHeader>;
  constructor(
    private spinner: NgxSpinnerService,
    private consumerService: ConsumerService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.loadLinks(1);
    this.consumerService.switchLn.subscribe((ln: any) => {
      console.log("inside ln sub", ln);
      if (ln && ln.ln) this.translate.use(ln.ln);
    });
  }

  loadLinks = async (page = null) => {
    this.page = page;
    this.spinner.show();
    try {
      let { result, total } = await this.consumerService.getLinks({
        page: page,
        limit: this.pageCount,
        search: this.searchText,
      });
      this.totalPage = total;
      this.links = result;
    } catch (err) {
      let message =
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    } finally {
      this.spinner.hide();
    }
  };

  public onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    // sorting countries
    if (direction === "" || column === "") {
    } else {
      this.links = [...this.links].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === "asc" ? res : -res;
      });
    }
  }

  createLinkModal() {}

  setPage = (page) => {
    this.loadLinks(page);
  };

  // goToProduct = (event, id) => {
  //   event.stopPropagation();
  //   this.router.navigate([`consumer/Links/${id}`]);
  // };

  // getProduct = async () => {
  //   this.spinner.show();
  //   try {
  //     this.product = await this.consumerService.getLinks();
  //     this.spinner.hide();
  //     this.editLink = true;
  //   } catch (err) {
  //     this.spinner.hide();
  //     this.toastr.error(
  //       err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
  //     );
  //   }
  // };

  showList = (event) => {
    this.editLink = false;
    //this.searchText = '';
    this.page = 0;
    this.loadLinks();
    this.router.navigate([`manager/Links`]);
  };

  openDeleteLinkconfirmation(id) {
    const modalRef = this.modalService.open(DeleteLinkConfirmationComponent);
    modalRef.componentInstance.message = "Are you sure you want to delete?";
    var that = this;
    modalRef.closed.subscribe({
      next(value) {
        if (value) {
          that.removeLink(id);
        }
      },
    });
  }

  openCreateLinkDialog() {
    const modalRef = this.modalService.open(CreateLinkComponent);
    var that = this;
    modalRef.closed.subscribe({
      next: (val) => {
        if (val) {
          that.loadLinks(1);
        }
      },
    });
  }

  removeLink(id: string) {
    try {
      this.consumerService.deleteLinkById(id).then((res) => {
        console.log(res);
        this.loadLinks(1);
      });
    } catch (err) {
      console.log(err);
    }
    // .subscribe({
    //   next: (res)=>{
    //     console.log(res);
    //   },
    //   error(err) {
    //       console.error(err);
    //   },
    // });
  }
}
