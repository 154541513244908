import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { URLS } from "../common/constants";
import { getFromStorage } from "../common/utils";
import { call, uploadFileToS3 } from "./backend.service";
const util = require("util");
@Injectable({
  providedIn: "root",
})
export class ConsumerService {
  public loggedInUser: BehaviorSubject<object> = new BehaviorSubject(null);
  public switchLn: BehaviorSubject<object> = new BehaviorSubject(null);
  constructor(private _http: HttpClient) {}

  getUser = async () => {
    let { success, result } = await call(URLS.USER, "get");
    if (success) {
      return result;
    }
  };

  getApi = async (page = null, count = null) => {
    let queryParams = {
      page: page,
      limit: count,
    };
    let results = await call(URLS.GET_APIS, "get", null, queryParams);
    if (results.success) {
      return results;
    }
  };

  AddApi = async (params) => {
    return await call(URLS.POST_APIS, "put", params);
  };

  getProducts = async (page, count) => {
    let queryParams = {
      page: page,
      limit: count,
    };
    let result = await call(URLS.GET_PRODUCTS, "get", null, queryParams);
    if (result.success) {
      return result;
    }
  };

  getLinks = async (filter: { page: number; limit: number; search: string }) => {
    const url = util.format(URLS.GET_LINKS);
    let { success, result ,total} = await call(`${url}?page=${filter.page}&limit=${filter.limit}&ean=${filter.search}`, "get");
    if (success) return {result ,total};
  };

  addLink = async (payload: any) => {
    return await call(URLS.ADD_LINK, "post", payload);
  };

  deleteLinkById = async (id) => {
    const url = util.format(`${URLS.DELETE_LINK}/${id}`);
    return await call(url, "del");
  };

  getProductById = async (id) => {
    const url = util.format(URLS.GET_PRODUCT_BY_ID, id);
    let { success, result } = await call(url, "get");
    if (success) return result;
  };

  getReport = async (start_date, end_date = null) => {
    let queryParams = {};
    queryParams["start_date"] = start_date;
    if (end_date) queryParams["end_date"] = end_date;
    let result = await call(URLS.GET_REPORTS, "get", null, queryParams);
    if (result.success) {
      return result;
    }
  };

  getNewReport = async () => {
    let queryParams = {};
    let result = await call(URLS.GET_REPORTS_NEW, "get", null, queryParams);
    if (result.success) {
      return result;
    }
  };

  getMetaData = async (type) => {
    const url = util.format(URLS.GET_METADATA, type);
    let { success, results } = await call(url, "get");
    if (success) {
      return results;
    }
  };

  updateFile = async (params) => {
    return await call(URLS.UPDATE_FILE, "put", params);
  };

  uploadFile = async (url, file) => {
    return await uploadFileToS3(url, file);
  };

  putUser = async (data) => {
    return await call(URLS.USER, "put", data);
  };

  updateProduct = async (params) => {
    return await call(
      `${URLS.UPDATE_PRODUCT}/${params.url}`,
      "put",
      params.data
    );
  };

  signup = (params) => {
    return this._http.post(
      `${environment.amplify.API.endpoints[0].endpoint}/signup`,
      { ...params }
    );
  };

  getEanStats = async () => {
    return await call("/ean-stats", "get");
  };

  loadUser = async () => {
    if (getFromStorage("consumer")) {
      return getFromStorage("consumer");
    } else {
      return await this.getUser();
    }
  };
  getReportEan = async (start_date, end_date) => {
    let queryParams = {};
    queryParams["start_date"] = start_date;
    if (end_date) queryParams["end_date"] = end_date;
    let result = await call(URLS.GET_REPORTS_EAN, "get", null, queryParams);
    if (result) {
      return result;
    } else {
      return [];
    }
  };
}
